module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"apex-auctions","defaultLang":"de-de","path":"/preview","previews":true,"pages":[{"type":"Page","match":"/:uid","component":"/opt/build/repo/src/templates/page.jsx"},{"type":"Page","match":"/","component":"/opt/build/repo/src/templates/page.jsx"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Apex Auctions","short_name":"Apex","start_url":"/","background_color":"#f8f8f7","theme_color":"#003882","display":"minimal-ui","icon":"src/images/Apex-Auctions_Icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b30f13c253ac010ea3c24233667ca0f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-W2MT9CW","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
